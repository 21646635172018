<template>
  <div class="switchGrid">
    <grid-list class="gridList"></grid-list>
    <div>
      <div class="folder-box">
        <bookmark-info></bookmark-info>
      </div>
      <div class="device-box">
        <device-box></device-box>
      </div>
    </div>
  </div>
</template>
<script>
import GridList from './gridList'
import BookmarkInfo from './bookmarkInfo'
import DeviceBox from './deviceBox'
export default {
  data () {
    return {
      hideInterval: null
    }
  },
  components: {
    GridList,
    BookmarkInfo,
    DeviceBox
  },
  beforeRouteEnter (to, from, next) {
    $('#tvu-top-header,#pro_nav_icon_bg,#pro_nav').hide()
    $('#main-box').css({ height: '100%' })
    next()
  },
  created () {
    this.hideInterval = setInterval(() => {
      $('#tvu-top-header,#pro_nav_icon_bg,#pro_nav').hide()
      $('#main-box').css({ height: '100%' })
    }, 30)
  },
  mounted () {
    $('#tvu-top-header,#pro_nav_icon_bg,#pro_nav').hide()
    $('#main-box').css({ height: '100%' })
  },
  beforeDestroy () {
    if (this.hideInterval) clearInterval(this.hideInterval)
  }
}
</script>
<style lang='less' scoped>
.switchGrid {
  height: 100%;
  .gridList {
    width: 420px;
    height: 100%;
  }
  .folder-box {
    height: 48%;
    width: calc(100% - 420px);
    padding: 15px 10px 0;
    float: left;
  }
  .device-box {
    height: 50%;
    width: calc(100% - 420px);
    padding: 0;
    float: left;
  }
}
</style>
