import { render, staticRenderFns } from "./VideoButton.vue?vue&type=template&id=893ab018&scoped=true"
import script from "./VideoButton.vue?vue&type=script&lang=js"
export * from "./VideoButton.vue?vue&type=script&lang=js"
import style0 from "./VideoButton.vue?vue&type=style&index=0&id=893ab018&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "893ab018",
  null
  
)

export default component.exports